import React, { useState } from "react";
import * as styles from "./select.module.scss";
import "./select.scss";
import Select from "react-select";
import cn from "classnames";
import { useField } from "formik";

const SelectInput = ({ label, name, options, isDisabled }) => {
  const [meta, helpers] = useField(name);
  const [isFocus, setIsFocus] = useState(false);
  const [isLabelActive, setIsLabelActive] = useState(true);
  const [isChanged, setIsChanged] = useState(true);

  const onFocus = () => {
    setIsFocus(true);
    setIsLabelActive(true);
  };

  const onChangeSelect = (e) => {
    setIsFocus(false);

    if (e.value) {
      setIsChanged(true);
      setIsLabelActive(true);
    }
  };

  const onBlur = (e) => {
    setIsFocus(false);
    if (!isChanged) {
      setIsLabelActive(false);
    }
  };

  return (
    <div className={cn(styles.inputWrap, isFocus && styles.isFocused)}>
      <label
        className={cn((isLabelActive || isChanged) && styles.isLabelActive)}
      >
        {label}
      </label>
      <Select
        options={options}
        // onChange={(e) => {
        //   onChangeSelect(e);
        //   onChange(e);
        // }}

        className={cn("custom-select-container", isFocus && "isFocused")}
        classNamePrefix="custom-select"
        placeholder=""
        blurInputOnSelect
        defaultValue={options[0]}
        isSearchable={false}
        isDisabled={isDisabled}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {e.icon && <div className="custom-select-icon">{e.icon}</div>}
            <span>{e.label}</span>
          </div>
        )}
        onChange={(value) => {
          helpers.setValue(value.label);
          onChangeSelect(value);
        }}
        onBlur={() => {
          helpers.setTouched(false);
          onBlur();
        }}
        onFocus={onFocus}
        // menuIsOpen={true}
      />
      <div
        className={cn(
          styles.inputWrapBorder,
          meta.touched && meta.error && styles.inputWrapBorderError
        )}
      ></div>
      {meta.touched && meta.error && (
        <div className={styles.inputError}>{meta.error}</div>
      )}
    </div>
  );
};

export default SelectInput;
