import React, { useState } from "react";
// import Axios from "axios";
import * as Yup from "yup";
import * as styles from "./ContactForm.module.scss";
import Container from "../../components/container/container";
import CustomButton from "../../components/customButton/CustomButton";
import Input from "../../components/form/input";
import SelectInput from "../../components/form/select";
import cn from "classnames";
import FormikErrorScroll from "formik-error-scroll";

import { Field, Form, Formik } from "formik";
// import CustomLink from "../../components/customLink/CustomLink";

import Icon from "../../svg/chains/etherium.svg";
import Icon2 from "../../svg/chains/bnb.svg";
import Icon3 from "../../svg/chains/polygon.svg";
import Icon4 from "../../svg/chains/avalanche.svg";
import Icon5 from "../../svg/chains/harmony.svg";
import Icon6 from "../../svg/chains/fantom.svg";

const FormSchema = Yup.object().shape({
  project: Yup.string().required("This Field Is Required."),
  website: Yup.string().required("This Field Is Required."),
  blockchain: Yup.string().required("This Field Is Required."),
  email: Yup.string()
    .email("Please Enter A Valid Email Address.")
    .required("This Field Is Required."),
  interested: Yup.string().required("This Field Is Required."),
});

const ContactForm = () => {
  const [successState, setSuccessState] = useState(false);

  const interestedInOptions = [
    { value: "lossless-vault-protection", label: "Lossless Vault Protection" },
    {
      value: "token-security-implementation",
      label: "Token security implementation",
    },
    { value: "we-got-hacked-help", label: "We got hacked, help!" },
  ];

  const blockchainOptions = [
    { value: "ethereum", label: " Ethereum", icon: <Icon /> },
    {
      value: "binance",
      label: "Binance Smart Chain",
      icon: <Icon2 />,
    },
    { value: "polygon", label: "Polygon", icon: <Icon3 /> },
    { value: "harmony", label: "Harmony", icon: <Icon5 /> },
    { value: "avalanche", label: "Avalanche", icon: <Icon4 /> },
    { value: "fantom", label: "Fantom", icon: <Icon6 /> },
  ];

  const initialValues = {
    project: "",
    website: "",
    blockchain: blockchainOptions[0].label,
    email: "",
    telegram: "",
    interested: interestedInOptions[0].label,
  };

  return (
    <section className={cn(styles.contactForm)}>
      <Container>
        <div className={styles.contactFormWrap}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={FormSchema}
            onSubmit={(values, actions) => {
              const fetchOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  query: `{
                    productLead(
                      contactEmail:"${values.email}",
                      projectName:"${values.project}",
                      blockchain: "${values.blockchain}",
                      projectWebsite:"${values.website}",
                      contactTelegram: "${values.telegram}",
                      interest:"${values.interested}"
                    )
                  }`,
                }),
              };

              fetch(
                "https://platform-staging-api.lossless.cash/graphql",
                fetchOptions
              )
                .then((response) => response.json())
                .then((response) => {
                  actions.setSubmitting(false);
                  setSuccessState(true);
                })
                .catch((err) => {
                  console.log(err);
                  // actions.setSubmitting(false);
                  // setSuccessState(true);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field name="project">
                  {({ field, form: { touched, errors }, meta }) => (
                    <>
                      <Input
                        type="text"
                        label="Project name"
                        disabled={successState}
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <Field name="website">
                  {({ field, form: { touched, errors }, meta }) => (
                    <>
                      <Input
                        type="text"
                        label="Project website"
                        {...field}
                        disabled={successState}
                      />
                    </>
                  )}
                </Field>
                <SelectInput
                  options={blockchainOptions}
                  label="Blockchain"
                  name="blockchain"
                  isDisabled={successState}
                />
                <Field name="email">
                  {({ field, form: { touched, errors }, meta }) => (
                    <>
                      <Input
                        type="text"
                        label="Contact email"
                        {...field}
                        disabled={successState}
                      />
                    </>
                  )}
                </Field>
                <Field name="telegram">
                  {({ field, form: { touched, errors }, meta }) => (
                    <>
                      <Input
                        type="text"
                        label="Contact telegram"
                        {...field}
                        disabled={successState}
                      />
                    </>
                  )}
                </Field>
                <SelectInput
                  options={interestedInOptions}
                  label="Interested in"
                  name="interested"
                  isDisabled={successState}
                />

                <CustomButton
                  text="Submit"
                  type="blue"
                  size="big"
                  value="Submit"
                  loading={isSubmitting}
                  success={successState}
                />

                <FormikErrorScroll
                  offset={-150}
                  align={"top"}
                  focusDelay={0}
                  ease={"linear"}
                  duration={300}
                />
                {/* <div>
             {state.mailSent && <div className="success">success</div>}
             {state.error && <div className="error">{state.error}</div>}
           </div> */}
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </section>
  );
};

export default ContactForm;
