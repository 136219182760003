import React, { useState } from "react";
import * as styles from "./input.module.scss";
import cn from "classnames";
import { useField } from "formik";

const Input = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [isFocus, setIsFocus] = useState(false);
  const [isLabelActive, setIsLabelActive] = useState(false);

  const onFocus = () => {
    setIsFocus(true);
    setIsLabelActive(true);
  };
  const onBlur = (e) => {
    setIsFocus(false);
    let value = e.target.value;
    value.length > 0 ? setIsLabelActive(true) : setIsLabelActive(false);
  };

  return (
    <div className={cn(styles.inputWrap, isFocus && styles.isFocused)}>
      <label className={cn(isLabelActive && styles.isLabelActive)}>
        {label}
      </label>
      <input
        // type={type}
        // placeholder={placeholder}
        // value={value}
        // label={label}

        {...field}
        {...props}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <div
        className={cn(
          styles.inputWrapBorder,
          meta.touched && meta.error && styles.inputWrapBorderError
        )}
      ></div>

      {meta.touched && meta.error && (
        <div className={styles.inputError}>{meta.error}</div>
      )}
    </div>
  );
};

export default Input;
