import * as React from "react";
import Layout from "../components/layout/layout";
// import TextPage from "../sections/textPage/TextPage";
import Hero from "../sections/hero/hero";
import ContactForm from "../sections/contactForm/ContactForm";
// import Image from "../images/for-developers-hero.jpg";
import FadeIn from "../components/fade/fade";

import HeroVideo from "../videos/dev.mp4";

const ContactUsPage = () => {
  return (
    <Layout pageTitle="Contact us - Lossless">
      <Hero
        title="Application Form"
        text="Please fill in the required information and our team will get back to you as soon as possible!"
        margin="0 auto"
        align="center"
        titleMaxWidth="71.4rem"
        textMaxWidth="53.6rem"
        // backgroundUrl={Image}
        video={HeroVideo}
      />
      <FadeIn>
        <ContactForm />
      </FadeIn>
    </Layout>
  );
};

export default ContactUsPage;
